import { SmileOutlined } from '@ant-design/icons';
import { useStoreActions } from 'easy-peasy';
import ApiResourceDataTable from '../datatables/ApiResourceDataTable';
import { Tooltip } from 'antd';

const Links = () => {
  const { openModal, closeModal } = useStoreActions((actions) => actions.modal);

  const getStatusClass = (status = null) => {
    if (status === 'active') return 'bg-green-500';
    if (status === 'inactive') return 'bg-red-500';
    if (status === 'warning') return 'bg-yellow-500';
    return 'bg-gray-300';
  };

  const getStatusDescription = (status = null) => {
    if (status === 'active') return 'This link is active.';
    if (status === 'inactive') return 'This link is inactive.';
    if (status === 'warning') return 'This link has some warnings.';
    return 'This link has not been crawled yet.';
  };

  const renderStatus = (params = null) => {
    return (
      <div className='flex h-full flex-row items-center gap-2'>
        <Tooltip title={getStatusDescription(params.row.latest_crawl?.status)}>
          <button
            onClick={() => {
              openModal({
                name: 'view-crawls',
                props: {
                  linkId: params.row.id,
                },
              });
            }}
            className={`${getStatusClass(
              params.row.latest_crawl?.status,
            )} h-4 w-4 rounded text-xs text-white`}
          ></button>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      customCellRenderer: (params) => (
        <span className='font-semibold text-gray-700'>{params.row.id}</span>
      ),
    },
    {
      field: 'referring_site',
      flex: 1,
      headerName: 'Referring Site',
      minWidth: 200,
      customCellRenderer: (params) => (
        <a
          href={params.row.referring_site}
          target='_blank'
          rel='noreferrer'
          className='text-indigo-500'
        >
          {params.row.referring_site}
        </a>
      ),
    },
    {
      field: 'target_url',
      flex: 1,
      headerName: 'Target URL',
      minWidth: 200,
      customCellRenderer: (params) => (
        <a
          href={params.row.target_url}
          target='_blank'
          rel='noreferrer'
          className='text-indigo-500'
        >
          {params.row.target_url}
        </a>
      ),
    },
    {
      field: 'anchor_text',
      flex: 1,
      headerName: 'Anchor Text',
      minWidth: 200,
      customCellRenderer: (params) => params.row.anchor_text,
    },
    {
      field: 'price',
      headerName: 'Price',
      customCellRenderer: (params) => params.row.price,
    },
    {
      field: 'real_price',
      headerName: 'Real Price',
    },
    {
      field: 'deal_id',
      headerName: 'Deal ID',
      customCellRenderer: (params) => (
        <a
          href={`/admin/deals/${params.row.deal_id}/edit`}
          target='_blank'
          className='text-indigo-500'
        >
          {params.row.deal_id}
        </a>
      ),
    },
    {
      field: 'notes',
      flex: 1,
      headerName: 'Notes',
      customCellRenderer: (params) => params.row.notes,
    },
    {
      field: 'live_date',
      headerName: 'Live Month',
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Status',
      maxWidth: 100,
      align: 'center',
      customCellRenderer: (params) => renderStatus(params),
    },
  ];
  return (
    <div>
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <ApiResourceDataTable
            columns={columns}
            endpoint='links'
            onEditButtonClick={(modelId = null) => {
              openModal({
                name: 'edit-link',
                props: {
                  modelId: modelId,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Links;
