import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import ApiService from 'services/ApiService';

const CsvExportButton = ({
  endpoint = '/links/export',
  additionalParams = {},
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // access query parameters
  const searchQuery = queryParams.get('q');

  const fetchCsv = async () => {
    const response = await ApiService.get(
      process.env.REACT_APP_API_URL + endpoint,
      {
        responseType: 'blob',
        params: { search: searchQuery, ...additionalParams },
      },
    );
    return response.data;
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: fetchCsv,
    mutationKey: ['export-csv'],
    onSuccess: (data) => {
      console.log(data);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      // remove first slash from the endpoint
      const fileName = endpoint.replace(/^\//, '') + '.csv';
      link.setAttribute('download', fileName); // or any other file name
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    onError: (error) => {
      console.error('Error downloading the CSV file', error);
    },
  });

  return <span onClick={() => mutate()}>Export to CSV</span>;
};

export default CsvExportButton;
