import React, { useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { useDebouncedCallback } from 'use-debounce';

const SearchInput = ({ value, onChange, loading = false }) => {
  return (
    <div className='flex flex-row items-center gap-x-3'>
      <div className='flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]'>
        <p className='pl-3 pr-2 text-sm text-gray-500'>
          {loading ? <FaSpinner className='animate-spin' /> : <FiSearch />}
        </p>
        <input
          type='text'
          value={value}
          onChange={onChange}
          placeholder='Search...'
          className='block h-full w-full rounded-full bg-lightPrimary py-3 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit'
        />
      </div>
    </div>
  );
};

export default SearchInput;
