import React from 'react';
import { MdAdd } from 'react-icons/md';

const CreateButton = ({ onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`flex h-9 items-center gap-1 rounded-lg bg-green-500 px-3 text-white transition hover:cursor-pointer hover:bg-green-600${
        className || ''
      }`}
    >
      <span>Create</span>
      <MdAdd className='text-xl' />
    </button>
  );
};

export default CreateButton;
