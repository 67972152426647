import { DataGrid } from '@mui/x-data-grid';
import { Dropdown, Pagination, Switch } from 'antd';
import Card from 'components/card';
import SearchInput from 'components/inputs/SearchInput';
import { useStoreActions } from 'easy-peasy';
import { useEffect, useState } from 'react';
import { IoIosMore, IoMdRefresh, IoMdTrash } from 'react-icons/io';
import { IoDocument } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import ApiService from 'services/ApiService';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import CsvExportButton from '../links/components/CsvExportButton';

export default function ApiResourceDataTable({
  endpoint = '',
  columns = [],
  actions = [],
  onDeleteButtonClick = () => {},
  onEditButtonClick = () => {},
  onCreateButtonClick = () => {},
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState({});
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('desc');
  const [colDefs, setColDefs] = useState([]);
  const { openModal, closeModal } = useStoreActions((actions) => actions.modal);
  const [selectedModels, setSelectedModels] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);

  useEffect(() => {
    let _cols = columns;
    if (columns) {
      _cols = _cols.map((column) => {
        if (column.field == 'actions') return column;
        column.renderCell = function (params) {
          return advancedSearchFilterCellRenderer(
            params,
            column.customCellRenderer || (() => {}),
          );
        };
        return column;
      });
    }
    setColDefs([
      ..._cols,
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: (params) => {
          if (params.id === 'search_filter') return null;
          return (
            <div className='flex h-full items-center gap-2'>
              <button
                onClick={() => {
                  if (onEditButtonClick) onEditButtonClick(params.id);
                }}
                className='flex aspect-square w-6 items-center justify-center rounded-lg  bg-indigo-200/70 text-indigo-500'
              >
                <MdEdit className='h-4 w-4' />
              </button>
              <button
                href={`/admin/links/${params.row.id}`}
                className='flex aspect-square w-6 items-center justify-center rounded-lg bg-red-200/70  text-red-500'
                onClick={() => {
                  if (onDeleteButtonClick) onDeleteButtonClick(params.id);
                  openModal({
                    name: 'confirmation',
                    props: {
                      onConfirm: () => {
                        deleteMutation.mutate([params.id]);
                        closeModal();
                      },
                      onClose: () => {
                        closeModal();
                      },
                      title: 'Delete confirmation',
                      message: `Are you sure you want to delete this?`,
                    },
                  });
                }}
              >
                <IoMdTrash className='h-4 w-4' />
              </button>
            </div>
          );
        },
      },
    ]);
  }, [columns, showAdvancedFilters]);

  useEffect(() => {
    if (!showAdvancedFilters) {
      setFilters({});
    }
    if (showAdvancedFilters) {
      setSearchQuery('');
    }
  }, [showAdvancedFilters]);

  useEffect(() => {
    if (searchQuery) {
      setShowAdvancedFilters(false);
    }
  }, [searchQuery]);

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      setFilters({
        ...filters,
        ...value,
      });
    },
    // delay in ms
    1000,
  );

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setPage(1);
    }
  }, [filters]);

  const { data, isLoading, isFetching } = useQuery(
    [
      `${endpoint}`,
      {
        ...filters,
        page,
        sort_field: sortField,
        sort_order: sortOrder,
        per_page: perPage,
        ...(debouncedSearchQuery.length > 3 && {
          quick_search: debouncedSearchQuery,
        }),
      },
    ],
    async () => {
      const response = await ApiService.get(
        process.env.REACT_APP_API_URL + '/' + endpoint,
        {
          params: {
            ...filters,
            page,
            sort_field: sortField,
            sort_order: sortOrder,
            per_page: perPage,
            ...(debouncedSearchQuery.length > 3 && {
              quick_search: debouncedSearchQuery,
            }),
          },
        },
      );
      return response.data;
    },
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const deleteMutation = useMutation(
    (ids) => ApiService.post(endpoint + '/bulk-delete', { ids }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${endpoint}`]);
      },
    },
  );

  // reset page on sort
  useEffect(() => {
    setPage(1);
  }, [sortField, sortOrder]);

  const advancedSearchFilterCellRenderer = (params, callback = () => {}) => {
    if (!showAdvancedFilters) return callback(params);
    if (params.id === 'search_filter') {
      return (
        <div className='w-full'>
          <input
            type='text'
            className='search-input h-10 w-full rounded-xl bg-lightPrimary px-4 focus:outline-none'
            placeholder='Filter'
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => {
              debounced({
                [params.field]: e.target.value,
              });
            }}
          />
        </div>
      );
    }
    return callback(params);
  };

  return (
    <Card
      extra={`w-full h-full px-6 pb-6 sm:overflow-x-auto transition duration-100 pt-4 ${
        isFetching && 'pointer-events-none'
      }`}
    >
      <div className='flex gap-3'>
        <SearchInput
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          loading={isLoading}
        />
        <div className='flex w-full items-center justify-between gap-2'>
          <div className='flex items-center gap-2'>
            <Switch
              onChange={(checked) => {
                setShowAdvancedFilters(checked);
              }}
              checked={showAdvancedFilters}
              className='bg-gray-100'
              id='switch1'
            />
            <label for='switch1' className='cursor-pointer select-none'>
              Advanced Search
            </label>
          </div>
          <div className='flex gap-2'>
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    label: (
                      <CsvExportButton
                        endpoint={`/${endpoint}/export`}
                        additionalParams={filters}
                      />
                    ),
                    icon: <IoDocument />,
                  },
                  {
                    key: '2',
                    label: <span>Clear all filters</span>,
                    icon: <IoMdRefresh />,
                    onClick: () => {
                      setFilters({});
                      document
                        .querySelectorAll('.search-input')
                        .forEach((e) => (e.value = ''));
                    },
                  },
                  {
                    key: '3',
                    label: (
                      <span className='text-red-500'>Delete selected</span>
                    ),
                    icon: <IoMdTrash className='text-red-500' />,
                    onClick: () => {
                      openModal({
                        name: 'confirmation',
                        props: {
                          onConfirm: () => {
                            deleteMutation.mutate(selectedModels);
                            closeModal();
                          },
                          onClose: () => {
                            closeModal();
                          },
                          title: 'Delete confirmation',
                          message: `Are you sure you want to delete selected?`,
                        },
                      });
                    },
                  },
                ],
              }}
            >
              <button
                onClick={(e) => e.preventDefault()}
                className='flex h-9 w-9 items-center justify-center rounded-lg bg-gray-100 text-xl'
              >
                <IoIosMore />
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className='flex min-h-[633.5px] flex-col'>
        <DataGrid
          onRowSelectionModelChange={(e) => {
            setSelectedModels(e);
          }}
          rows={
            showAdvancedFilters
              ? [
                  {
                    id: 'search_filter',
                  },
                  ...(Array.isArray(data?.data) ? data?.data : []), // Ensure it's an array
                ]
              : data?.data
          }
          loading={isFetching}
          columns={colDefs}
          autosizeOnMount
          isRowSelectable={(params) => {
            if (params.id === 'search_filter') return false;
            return true;
          }}
          headerFilters
          hideFooter
          filterMode='server'
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          sx={{
            border: 0,
            borderColor: 'primary.light',
            '&, [class^=MuiDataGrid]': { border: 'none' },
            fontFamily: 'DM Sans',
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton',
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          checkboxSelection
          disableRowSelectionOnClick
          paginationMode='server'
          sortingMode='server'
          disableColumnFilter
          onSortModelChange={(onSortModelChange) => {
            if (onSortModelChange.length == 0) {
              setSortField('id');
              setSortOrder('desc');
              return;
            }
            setSortField(onSortModelChange[0].field);
            setSortOrder(onSortModelChange[0].sort);
          }}
        />
        <div className='flex justify-between'>
          <div className='flex items-center gap-2'>
            <span className='text-sm font-medium text-gray-600 dark:text-white'>
              Showing {data?.meta?.from} to {data?.meta?.to} of{' '}
              {data?.meta?.total}
            </span>
          </div>
          <Pagination
            defaultCurrent={page}
            total={data?.meta?.total}
            onChange={(e) => setPage(e)}
            pageSizeOptions={[10, 25, 50]}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={(e, e2) => {
              setPage(1);
              setPerPage(e2);
            }}
          />
        </div>
      </div>
    </Card>
  );
}
